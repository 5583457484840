export const domains = [
  'fintros.com',
  'fintros.nyc3.digitaloceanspaces.com',
  'jedi-replica.nyc3.digitaloceanspaces.com',
  'jedi.nyc3.digitaloceanspaces.com',
  'logo.clearbit.com',
  'media.licdn.com',
  'pbs.twimg.com',
  'upload.wikimedia.org',
  'res.cloudinary.com',
  'www.goldmansachs.com',
  'stories.td.com',
  'media2.vault.com',
  'www.beqom.com',
  'www.princessroyaltrainingawards.com',
  'static1.squarespace.com',
  'brokerchalk.com',
]
